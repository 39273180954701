import React from "react";

const Success = () => {
  return (
    <div>
      <button style={{ border: "2px solid red" }}>Click me</button>
    </div>
  );
};

export default Success;
